import React from "react";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { Goal } from "../types";

export function ThreeGoalsList() {
  const [goals] = useStateWithLocalStorage<string[]>("dd/onboarding/goal", []);

  return (
    <ul className="list-none text-left text-lg max-w-xs sm:max-w-sm p-0 m-0">
      <li className="flex flex-row items-start space-x-2">
        <GreenStar />
        <span>{getGoalLabel(goals[0] as Goal) || "Lose weight and feel great"}</span>
      </li>

      <li className="flex flex-row items-start space-x-2 mt-2">
        <GreenStar />
        <span>{getGoalLabel(goals[1] as Goal) || "Find which foods work for you"}</span>
      </li>
      <li className="flex flex-row items-start space-x-2 mt-2">
        <GreenStar />
        <span>
          {getGoalLabel(goals[2] as Goal) || "Build healthy habits to keep the weight off and improve health"}
        </span>
      </li>
    </ul>
  );
}

export function GreenStar({ className }: { className?: string }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="shrink-0 mt-1"
    >
      <path
        d="M8.5 0l2.296 6.204L17 8.5l-6.204 2.296L8.5 17l-2.296-6.204L0 8.5l6.204-2.296L8.5 0z"
        fill="#4FAE64"
      />
    </svg>
  );
}

function getGoalLabel(goal: Goal) {
  switch (goal) {
    case Goal.LOSE_WEIGHT:
      return "Lose weight so I can look and feel better";
    case Goal.IMPROVE_HEALTH:
      return "Improve my overall health and prevent disease";
    case Goal.MANAGE_CRAVINGS:
      return "Manage cravings, hunger, or emotional eating";
    case Goal.TREAT_HEALTH_CONDITION:
      return "Treat a specific health condition (such as type 2 diabetes, epilepsy, high blood pressure, PCOS, etc.)";
    case Goal.MEAL_PLANNING:
      return "Get help with meal planning and cooking inspiration";
    case Goal.FIND_SUPPORT:
      return "Find support from experts and others with similar experiences";
    case Goal.LEARN:
      return "Learn about health and nutrition from the latest evidence-based science";
    default:
      return null;
  }
}
