import { State } from "xstate";
import {
  Cuisine,
  Diet,
  DietChallenge,
  Dish,
  FoodType,
  Gender,
  HealthCondition,
  MeasurementSystem,
  Ranking,
} from "../../types/graphql-global-types";

export enum OnboardingStep {
  WELCOME = "welcome",
  GOAL = "goal",
  GENDER = "gender",
  HEIGHT_AGE = "height-age",
  WEIGHT_LOSS_GOAL = "weight-loss-goal",
  STEP_ONE_COMPLETION = "completion-1",
  ACTIVITY_LEVEL = "activity-level",
  STEP_TWO_COMPLETION = "completion-2",
  HEALTH_CONDITION = "health-condition",
  HEALTH_CONDITION_INFO = "health-info",
  STEP_THREE_COMPLETION = "completion-3",
  FEEL_ABOUT_FOOD = "feel-about-food",
  RELATABLE_SCENARIOS = "relatable-scenarios",
  STEP_FOUR_COMPLETION = "completion-4",
  CUISINE_INTEREST = "cuisine-interests",
  FOOD_PREFERENCES = "food-preferences",
  PROTEINS_TO_AVOID = "proteins-to-avoid",
  FOODS_TO_AVOID = "foods-to-avoid",
  STEP_FIVE_COMPLETION = "completion-5",
  BUDGET = "budget",
  COOKING_TIME = "cooking-time",
  STEP_SIX_COMPLETION = "completion-6",
  COOKING_SKILL = "cooking-skill",
  PROTEIN_LEVEL = "protein-level",
  CARB_LEVEL = "carb-level",
  DIET_CHALLENGES = "diet-challenges",
  MEAL_PER_DAY = "meals-per-day",
  LEFT_OVERS = "left-overs",
  SUBMIT = "submit",
  GENERATING = "generating",
}

export enum CarbLevel {
  UNKNOWN_CARB = "UNKNOWN_CARB",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum Goal {
  GOAL_UNSPECIFIED = "GOAL_UNSPECIFIED",
  LOSE_WEIGHT = "LOSE_WEIGHT",
  IMPROVE_HEALTH = "IMPROVE_HEALTH",
  MANAGE_CRAVINGS = "MANAGE_CRAVINGS",
  TREAT_HEALTH_CONDITION = "TREAT_HEALTH_CONDITION",
  MEAL_PLANNING = "MEAL_PLANNING",
  FIND_SUPPORT = "FIND_SUPPORT",
  LEARN = "LEARN",
}

export type MealFrequency = "3" | "2";

export interface OnboardingMachineContext {
  step: OnboardingStep;
  weight: WeightData;
  onboardingPayload: OnboardingPayload;
  firebaseUserId?: string;
}

interface WeightData {
  currentWeight?: number;
  goalWeight?: number;
  weightUnit?: MeasurementSystem;
}

export interface OnboardingPayload {
  avoidedFoods?: FoodType[];
  carbTolerance?: CarbLevel | null;
  weight?: number;
  goals?: Goal[];
  gender?: Gender;
  birthYear?: number;
  desiredDishes?: Dish[];
  desiredCuisines?: Cuisine[];
  diet?: Diet;
  dietChallenge?: DietChallenge;
  healthConditions?: HealthCondition[];
  proteinTolerance?: Ranking;
  physicalActivity?: Ranking;
  heightCm?: number;
  foodBudget?: Ranking;
  cookingSkill?: Ranking;
  cookingTimeExtent?: Ranking;
  dailyMealsCount?: number;
  measurementSystem?: MeasurementSystem;
  leftovers?: boolean;
}

export type OnboardingMachineState = State<
  OnboardingMachineContext,
  OnboardingMachineEvents,
  any,
  {
    value: any;
    context: OnboardingMachineContext;
  }
>;

interface UpdateUserPreferencesEvent {
  type: "UPDATE_USER_PREFERENCES";
}

export interface SetFirebaseUserIdEvent {
  type: "SET_FIREBASE_USER_ID";
  firebaseUserId: string;
}

export interface SetStepEvent {
  type: "SET_STEP";
  step: OnboardingStep;
}

export interface SetWeightEvent {
  type: "SET_WEIGHT";
  weight: WeightData;
}

export interface SetInitialOnboardingPayloadEvent {
  type: "SET_INITIAL_PAYLOAD";
  payload: OnboardingPayload;
}

export type SetOnboardingPayloadEvent = {
  [Payload in keyof OnboardingPayload]-?: {
    type: "SET_PAYLOAD";
    payload: Payload;
    value: OnboardingPayload[Payload];
  };
}[keyof OnboardingPayload];

export type OnboardingMachineEvents =
  | SetFirebaseUserIdEvent
  | UpdateUserPreferencesEvent
  | SetInitialOnboardingPayloadEvent
  | SetOnboardingPayloadEvent
  | SetStepEvent
  | SetWeightEvent;

export type OnboardingStepProps = {
  onDone?: () => void;
};
